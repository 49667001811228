.ep-grid{
    stroke-width: 0.5;
    color: #979797;

    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}


.ep-axis{
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}


.ep-axis-label{
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}


.ep-plot{
    width: 100%;
    position: relative;
}


.ep-plot-background{
    fill: white;
    opacity: 0.0;
    cursor: crosshair;
}


.ep-legend-text{
    cursor: pointer;
}


.ep-legend-text disabled{
    color: red;
}


.ep-tooltip-box{
    position: absolute;
    background-color: #d0b0d0c4;
    border-radius: 5px;
    padding: 5px;
    pointer-events: none;

    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}


.ep-tooltip-dot{
    position: absolute;
    background-color: black;
    width: 5px;
    height: 5px;
    border-radius: 50%;
    pointer-events: none;
}


.ep-rubberband{
    fill: steelblue;
    opacity: 0.35;
}


.ep-title{
    font-size: 1.2em;
    font-weight: bold;
}


.ep-tracker{
    stroke: red;
    stroke-width: 1;

    &.disabled{
        stroke-width: 0;
    }
}


.ep-dashed-line{
    stroke-dasharray: 5, 3;
}


.ep-ref-link{
    cursor: pointer;
}

.ep-ref-popup{
    z-index: 1000;
    position: absolute;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    background-color: #fff;
    padding: 10px;
    display: none;
}


.ep-ref-img{
    object-fit: contain;
    max-width: 500px;
    max-height: 500px;
}

.ep-event-marker{
    pointer-events: none;
    stroke: red;
    stroke-width: 2;
    z-index: 10000;
}

.ep-event-triangle{
    fill: red;
}

.ep-event-marker-mouse{
    fill: #6eff7d;
}

.ep-event-tooltip{
    position: absolute;
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    border-radius: 2px;
    padding: 5px;
    pointer-events: none;

    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
